const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['default'] = require('../middleware/default.js')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['verify-token'] = require('../middleware/verify-token.js')
middleware['verify-token'] = middleware['verify-token'].default || middleware['verify-token']

export default middleware
