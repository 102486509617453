
import { mapState } from "vuex";
import NestedSidebar from "@/components/bars/NestedSidebar";

export default {
  name: "MainSidebar",
  components: { NestedSidebar },
  props: {
    visible: Boolean,
    isAuth: Boolean,
    user: { type: Object, default: () => ({}) },
    navMenu: { type: Array, default: () => [] },
  },
  computed: {

    ...mapState("notification", ["totalNotification"]),
    userName() {
      const { first_name, first_name_kh, last_name, last_name_kh } =
        this.user || {};
      return this.$isKm()
        ? [first_name_kh, last_name_kh].join(" ")
        : [first_name, last_name].join(" ");
    },
    userEmail() {
      return this.user?.email;
    },

  },
  methods: {
    toggle(v) {
      this.$emit("update:visible", v);
    },
    login() {
      this.$emit("open-login");
      this.$emit("update:visible", false);
    },
    logout() {
      this.$emit("logout");
      this.$emit("update:visible", false);
    },
  },
};
