
export default {
  props: ["error"],
  layout: "blank",
  head() {
    return {
      title: "Startup Cambodia",
    };
  },
};
