
export default {
  name: "SearchResultItem",
  props: {
    title: String,
    description: String,
    thumbnail: String,
    to: String,
  },
};
