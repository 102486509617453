export const months = [
    { title: "January", value: "1", key: "months.1" },
    { title: "February", value: "2", key: "months.2" },
    { title: "March", value: "3", key: "months.3" },
    { title: "April", value: "4", key: "months.4" },
    { title: "May", value: "5", key: "months.5" },
    { title: "June", value: "6", key: "months.6" },
    { title: "July", value: "7", key: "months.7" },
    { title: "August", value: "8", key: "months.8" },
    { title: "September", value: "9", key: "months.9" },
    { title: "October", value: "10", key: "months.10" },
    { title: "November", value: "11", key: "months.11" },
    { title: "December", value: "12", key: "months.12" },
];
