import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b8589bec = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _e09f26aa = () => interopDefault(import('../pages/apply/index.vue' /* webpackChunkName: "pages/apply/index" */))
const _31f2807b = () => interopDefault(import('../pages/camdigikey-login.vue' /* webpackChunkName: "pages/camdigikey-login" */))
const _782c1346 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _6c5e42bd = () => interopDefault(import('../pages/ecosystem/index.vue' /* webpackChunkName: "pages/ecosystem/index" */))
const _299b54d7 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _17e743c1 = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _9df68166 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _5da1654e = () => interopDefault(import('../pages/account/begin_password_reset.vue' /* webpackChunkName: "pages/account/begin_password_reset" */))
const _2c6b298d = () => interopDefault(import('../pages/account/confirm-verify-email.vue' /* webpackChunkName: "pages/account/confirm-verify-email" */))
const _2b6a83b4 = () => interopDefault(import('../pages/ecosystem/#index.vue' /* webpackChunkName: "pages/ecosystem/#index" */))
const _229fa8ce = () => interopDefault(import('../pages/ecosystem/discover.vue' /* webpackChunkName: "pages/ecosystem/discover" */))
const _2b51dcd1 = () => interopDefault(import('../pages/ecosystem/discover/index.vue' /* webpackChunkName: "pages/ecosystem/discover/index" */))
const _0299efdc = () => interopDefault(import('../pages/ecosystem/discover/category/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/index" */))
const _5186663c = () => interopDefault(import('../pages/ecosystem/discover/detail.vue' /* webpackChunkName: "pages/ecosystem/discover/detail" */))
const _51e6050b = () => interopDefault(import('../pages/ecosystem/discover/category/investor/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/investor/index" */))
const _8a39ca18 = () => interopDefault(import('../pages/ecosystem/discover/category/mentor/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/mentor/index" */))
const _764eaf00 = () => interopDefault(import('../pages/ecosystem/discover/category/startup/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/startup/index" */))
const _4406621e = () => interopDefault(import('../pages/ecosystem/discover/category/individuals/_individualsId/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/individuals/_individualsId/index" */))
const _7b96d72e = () => interopDefault(import('../pages/ecosystem/discover/category/investor/_investorId/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/investor/_investorId/index" */))
const _4a882780 = () => interopDefault(import('../pages/ecosystem/discover/category/mentor/_mentorId/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/mentor/_mentorId/index" */))
const _54cdb87e = () => interopDefault(import('../pages/ecosystem/discover/category/startup/_startupId/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/startup/_startupId/index" */))
const _66bd1d40 = () => interopDefault(import('../pages/ecosystem/discover/category/_networkCategory/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/_networkCategory/index" */))
const _fd8a0f22 = () => interopDefault(import('../pages/ecosystem/discover/category/_networkCategory/_networkId/index.vue' /* webpackChunkName: "pages/ecosystem/discover/category/_networkCategory/_networkId/index" */))
const _d6447f8e = () => interopDefault(import('../pages/ecosystem/get-listed.vue' /* webpackChunkName: "pages/ecosystem/get-listed" */))
const _9cab21bc = () => interopDefault(import('../pages/program/category/index.vue' /* webpackChunkName: "pages/program/category/index" */))
const _2466e719 = () => interopDefault(import('../pages/resource/category.vue' /* webpackChunkName: "pages/resource/category" */))
const _84b41248 = () => interopDefault(import('../pages/resource/category/index.vue' /* webpackChunkName: "pages/resource/category/index" */))
const _06f9b38c = () => interopDefault(import('../pages/resource/category/_resource/index.vue' /* webpackChunkName: "pages/resource/category/_resource/index" */))
const _3dedfdd0 = () => interopDefault(import('../pages/resource/category/_resource/_resourceId.vue' /* webpackChunkName: "pages/resource/category/_resource/_resourceId" */))
const _4737b01b = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _3afff417 = () => interopDefault(import('../pages/user/profile/bio/index.vue' /* webpackChunkName: "pages/user/profile/bio/index" */))
const _c612064e = () => interopDefault(import('../pages/user/profile/change_password/index.vue' /* webpackChunkName: "pages/user/profile/change_password/index" */))
const _972157f6 = () => interopDefault(import('../pages/user/profile/connection/index.vue' /* webpackChunkName: "pages/user/profile/connection/index" */))
const _e74eb1d6 = () => interopDefault(import('../pages/user/profile/inbox/index.vue' /* webpackChunkName: "pages/user/profile/inbox/index" */))
const _79d9ec3f = () => interopDefault(import('../pages/user/profile/investor/index.vue' /* webpackChunkName: "pages/user/profile/investor/index" */))
const _656181b0 = () => interopDefault(import('../pages/user/profile/mentor/index.vue' /* webpackChunkName: "pages/user/profile/mentor/index" */))
const _b45d825c = () => interopDefault(import('../pages/user/profile/notification/index.vue' /* webpackChunkName: "pages/user/profile/notification/index" */))
const _67747fa2 = () => interopDefault(import('../pages/user/profile/save_list/index.vue' /* webpackChunkName: "pages/user/profile/save_list/index" */))
const _9d31dd68 = () => interopDefault(import('../pages/user/profile/startup/index.vue' /* webpackChunkName: "pages/user/profile/startup/index" */))
const _686bddfa = () => interopDefault(import('../pages/user/profile/network/connection.vue' /* webpackChunkName: "pages/user/profile/network/connection" */))
const _2841c550 = () => interopDefault(import('../pages/user/profile/inbox/_messageId.vue' /* webpackChunkName: "pages/user/profile/inbox/_messageId" */))
const _62339122 = () => interopDefault(import('../pages/user/profile/startup/_startupId.vue' /* webpackChunkName: "pages/user/profile/startup/_startupId" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43f1095e = () => interopDefault(import('../pages/resource/file/_fileId.vue' /* webpackChunkName: "pages/resource/file/_fileId" */))
const _f14da798 = () => interopDefault(import('../pages/account/reset-password-confirm/_.vue' /* webpackChunkName: "pages/account/reset-password-confirm/_" */))
const _c6a26512 = () => interopDefault(import('../pages/account/confirm-email/_.vue' /* webpackChunkName: "pages/account/confirm-email/_" */))
const _dcc693be = () => interopDefault(import('../pages/event/_eventId.vue' /* webpackChunkName: "pages/event/_eventId" */))
const _42331d1a = () => interopDefault(import('../pages/user/_userId/index.vue' /* webpackChunkName: "pages/user/_userId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _b8589bec,
    name: "about"
  }, {
    path: "/apply",
    component: _e09f26aa,
    name: "apply"
  }, {
    path: "/camdigikey-login",
    component: _31f2807b,
    name: "camdigikey-login"
  }, {
    path: "/contact",
    component: _782c1346,
    name: "contact"
  }, {
    path: "/ecosystem",
    component: _6c5e42bd,
    name: "ecosystem"
  }, {
    path: "/event",
    component: _299b54d7,
    name: "event"
  }, {
    path: "/privacy-policy",
    component: _245a834d,
    name: "privacy-policy"
  }, {
    path: "/program",
    component: _17e743c1,
    name: "program"
  }, {
    path: "/terms-of-use",
    component: _9df68166,
    name: "terms-of-use"
  }, {
    path: "/account/begin_password_reset",
    component: _5da1654e,
    name: "account-begin_password_reset"
  }, {
    path: "/account/confirm-verify-email",
    component: _2c6b298d,
    name: "account-confirm-verify-email"
  }, {
    path: "/ecosystem/#index",
    component: _2b6a83b4,
    name: "ecosystem-#index"
  }, {
    path: "/ecosystem/discover",
    component: _229fa8ce,
    children: [{
      path: "",
      component: _2b51dcd1,
      name: "ecosystem-discover"
    }, {
      path: "category",
      component: _0299efdc,
      name: "ecosystem-discover-category"
    }, {
      path: "detail",
      component: _5186663c,
      name: "ecosystem-discover-detail"
    }, {
      path: "category/investor",
      component: _51e6050b,
      name: "ecosystem-discover-category-investor"
    }, {
      path: "category/mentor",
      component: _8a39ca18,
      name: "ecosystem-discover-category-mentor"
    }, {
      path: "category/startup",
      component: _764eaf00,
      name: "ecosystem-discover-category-startup"
    }, {
      path: "category/individuals/:individualsId",
      component: _4406621e,
      name: "ecosystem-discover-category-individuals-individualsId"
    }, {
      path: "category/investor/:investorId",
      component: _7b96d72e,
      name: "ecosystem-discover-category-investor-investorId"
    }, {
      path: "category/mentor/:mentorId",
      component: _4a882780,
      name: "ecosystem-discover-category-mentor-mentorId"
    }, {
      path: "category/startup/:startupId",
      component: _54cdb87e,
      name: "ecosystem-discover-category-startup-startupId"
    }, {
      path: "category/:networkCategory",
      component: _66bd1d40,
      name: "ecosystem-discover-category-networkCategory"
    }, {
      path: "category/:networkCategory/:networkId",
      component: _fd8a0f22,
      name: "ecosystem-discover-category-networkCategory-networkId"
    }]
  }, {
    path: "/ecosystem/get-listed",
    component: _d6447f8e,
    name: "ecosystem-get-listed"
  }, {
    path: "/program/category",
    component: _9cab21bc,
    name: "program-category"
  }, {
    path: "/resource/category",
    component: _2466e719,
    children: [{
      path: "",
      component: _84b41248,
      name: "resource-category"
    }, {
      path: ":resource",
      component: _06f9b38c,
      name: "resource-category-resource"
    }, {
      path: ":resource/:resourceId",
      component: _3dedfdd0,
      name: "resource-category-resource-resourceId"
    }]
  }, {
    path: "/user/profile",
    component: _4737b01b,
    name: "user-profile",
    children: [{
      path: "bio",
      component: _3afff417,
      name: "user-profile-bio"
    }, {
      path: "change_password",
      component: _c612064e,
      name: "user-profile-change_password"
    }, {
      path: "connection",
      component: _972157f6,
      name: "user-profile-connection"
    }, {
      path: "inbox",
      component: _e74eb1d6,
      name: "user-profile-inbox"
    }, {
      path: "investor",
      component: _79d9ec3f,
      name: "user-profile-investor"
    }, {
      path: "mentor",
      component: _656181b0,
      name: "user-profile-mentor"
    }, {
      path: "notification",
      component: _b45d825c,
      name: "user-profile-notification"
    }, {
      path: "save_list",
      component: _67747fa2,
      name: "user-profile-save_list"
    }, {
      path: "startup",
      component: _9d31dd68,
      name: "user-profile-startup"
    }, {
      path: "network/connection",
      component: _686bddfa,
      name: "user-profile-network-connection"
    }, {
      path: "inbox/:messageId",
      component: _2841c550,
      name: "user-profile-inbox-messageId"
    }, {
      path: "startup/:startupId",
      component: _62339122,
      name: "user-profile-startup-startupId"
    }]
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }, {
    path: "/resource/file/:fileId?",
    component: _43f1095e,
    name: "resource-file-fileId"
  }, {
    path: "/account/reset-password-confirm/*",
    component: _f14da798,
    name: "account-reset-password-confirm-all"
  }, {
    path: "/account/confirm-email/*",
    component: _c6a26512,
    name: "account-confirm-email-all"
  }, {
    path: "/event/:eventId",
    component: _dcc693be,
    name: "event-eventId"
  }, {
    path: "/user/:userId",
    component: _42331d1a,
    name: "user-userId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
