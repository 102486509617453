export const shareOptions = [
    {
        text: "Linkedin",
        key: "menu.share_to.linkedin",
        icon: "mdi-linkedin",
        type: "linkedin",
    },
    {
        text: "Facebook",
        key: "menu.share_to.facebook",
        icon: "mdi-facebook",
        type: "facebook",
    },
    {
        text: "Twitter",
        key: "menu.share_to.twitter",
        icon: "mdi-twitter",
        type: "twitter",
    },
    {
        text: "Telegram",
        key: "menu.share_to.telegram",
        icon: "mdi-send-circle",
        type: "telegram",
    },
    {
        text: "Copy link",
        key: "menu.share_to.copy",
        icon: "mdi-content-copy",
        type: "copy"
    }
];

