
export default {
  props: ["title"],
  name: "MainFooter",
  data() {
    return {
      scrollBtn: false,
    };
  },
  computed: {
    footerMenus() {
      return [
        {
          text: this.$t("startup_cambodia"),
          children: [
            { title: this.$t("programs"), to: "/program" },
            { title: this.$t("event"), to: "/event" },
            {
              title: this.$t("footer.menu.posts"),
              to: "/resource/category/blog",
            },
            {
              title: this.$t("footer.menu.files"),
              to: "/resource/category/file",
            },
          ],
        },
        {
          text: this.$t("footer.ecosystem"),
          children: [
            { title: this.$t("footer.menu.stakeholders"), to: "/ecosystem" },
            {
              title: this.$t("footer.menu.startups"),
              to: "/ecosystem/discover/category/startup",
            },
            {
              title: this.$t("footer.menu.mentors"),
              to: "/ecosystem/discover/category/mentor",
            },
            {
              title: this.$t("footer.menu.investors"),
              to: "/ecosystem/discover/category/investor",
            },
          ],
        },
        {
          text: this.$t("footer.info"),
          children: [
            { title: this.$t("footer.menu.about"), to: "/about" },
            { title: this.$t("footer.menu.contact"), to: "/contact" },
            { title: this.$t("footer.menu.term_of_us"), to: "/terms-of-use" },
            { title: this.$t("footer.menu.privacy"), to: "/privacy-policy" },
          ],
        },
      ];
    },
    currentYear() {
      return this.$localeNumb(new Date().getFullYear());
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 70) {
        this.scrollBtn = true;
      } else if (window.scrollY < 70) {
        this.scrollBtn = false;
      }
    },
    scrollTop() {
      this.$vuetify.goTo(0, { duration: 0, offset: 0, easing: "linear" });
    },
  },
};
