
import { mapActions, mapState } from "vuex";
import NestedMenu from "@/components/Menu/NestedMenu";
import NestedNavMenu from "@/components/bars/NestedNavMenu";

export default {
  name: "MainNavbar",
  components: { NestedNavMenu, NestedMenu },
  props: {
    isTop: Boolean,
    isAuth: Boolean,
    user: { type: Object, default: () => ({}) },
    navMenu: { type: Array, default: () => [] },
  },
  data() {
    return {
      fullMenu: false,
    };
  },
  computed: {
    ...mapState("user", ["isLoading"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("notification", ["totalNotification"]),

    userPhoto() {
      return this.user?.photo || "/images/default-user.png";
    },
    userName() {
      const { first_name, first_name_kh, last_name, last_name_kh } =
        this.user || {};
      return this.$isKm()
        ? [first_name_kh, last_name_kh].join(" ")
        : [first_name, last_name].join(" ");
    },
    userEmail() {
      return this.user?.email;
    },
  },
  async fetch() {
    this.getNotificationCount();
  },
  methods: {
    ...mapActions("notification", ["getNotificationCount"]),
    
    toggleDrawer() {
      this.$emit("toggle-drawer");
    },
    login() {
      this.$emit("open-login");
    },
    logout() {
      this.$swal
        .fire({
          text: this.$t("dialog.question.logout"),
          type: "info",
          showCancelButton: true,
          reverseButtons: true,
          width: 400,
          confirmButtonColor: "#d33",
          cancelButtonText: this.$t("dialog.cancel"),
          confirmButtonText: this.$t("dialog.logout"),
        })
        .then((result) => {
          if (result.value) {
            this.$emit("logout");
          }
        });
    },
    openSearch() {
      this.$emit("open-search");
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (this.currentUser) {
          if (!!this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
          }
          // this.getNotificationCount();
          // this.searchTimer = setTimeout(() => {
          //   this.getNotificationCount();
          // }, 3000);
        }
      },
      deep: true,
      immediate: true,
    },
  }

  
};

