
const SEARCH_TYPE = {
  BLOGS: "blogs",
  FILES: "files",
  EVENTS: "events",
  PROGRAMS: "programs",
};
const PREFIX_ROUTES = {
  blogs: "/resource/category/blog/",
  files: "/resource/file/",
  events: "/event/",
  programs: "/program?id=",
  startup: "/ecosystem/discover/category/startup/",
  mentor: "/ecosystem/discover/category/mentor/",
  investor: "/ecosystem/discover/category/investor/",
  academic_institution: "/ecosystem/discover/category/academic-institution/",
  co_working_space: "/ecosystem/discover/category/co-working-space/",
  community_and_association:
    "/ecosystem/discover/category/community-and-association/",
  corporate: "/ecosystem/discover/category/corporate/",
  development_partner: "/ecosystem/discover/category/development-partner/",
  government_institution:
    "/ecosystem/discover/category/government-institution/",
  incubator_and_accelerator:
    "/ecosystem/discover/category/incubator-and-accelerator/",
  service_provider: "/ecosystem/discover/category/service-provider/",
  media: "/ecosystem/discover/category/media/",
};
export default {
  name: "DialogGlobalSearch",
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      SEARCH_TYPE,
      activeTab: "",
      isSearching: false,
      search: {
        keyword: "",
      },
      results: {
        blogs: [],
        files: [],
        events: [],
        programs: [],
      },
    };
  },
  computed: {
    hasResultAllTab() {
      return !!this.stakeholderMenu.length;
    },
    hasBlogResults() {
      return !!this.results.blogs?.length;
    },
    hasFileResults() {
      return !!this.results.files?.length;
    },
    hasEventResults() {
      return !!this.results.events?.length;
    },
    hasProgramResults() {
      return !!this.results.programs?.length;
    },

    stakeholderMenu() {
      let items = [
        {
          text: this.$t("global.search.tab.title.blogs"),
          key: SEARCH_TYPE.BLOGS,
        },
        {
          text: this.$t("global.search.tab.title.files"),
          key: SEARCH_TYPE.FILES,
        },
        {
          text: this.$t("global.search.tab.title.events"),
          key: SEARCH_TYPE.EVENTS,
        },
        {
          text: this.$t("global.search.tab.title.programs"),
          key: SEARCH_TYPE.PROGRAMS,
        },
        {
          text: this.$t("menu.startup"),
          key: "startup",
        },
        {
          text: this.$t("menu.mentor"),
          key: "mentor",
        },
        {
          text: this.$t("menu.investor"),
          key: "investor",
        },
        {
          text: this.$t("menu.academic-institution"),
          key: "academic_institution",
        },
        {
          text: this.$t("menu.co-working-space"),
          key: "co_working_space",
        },
        {
          text: this.$t("menu.community-and-association"),
          key: "community_and_association",
        },
        {
          text: this.$t("menu.corporate"),
          key: "corporate",
        },
        {
          text: this.$t("menu.development-partner"),
          key: "development_partner",
        },
        {
          text: this.$t("menu.government-institution"),
          key: "government_institution",
        },
        {
          text: this.$t("menu.incubator-and-accelerator"),
          key: "incubator_and_accelerator",
        },
        {
          text: this.$t("menu.service-provider"),
          key: "service_provider",
        },
        {
          text: this.$t("menu.media"),
          key: "media",
        },
      ];
      return items.filter((item) => this.results[item.key]?.length);
    },
  },
  watch: {
    "search.keyword"(v) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.doSearch();
      }, 800);
    },
    $route() {
      this.closeSearch();
    },
  },
  methods: {
    closeSearch() {
      this.$emit("update:isVisible", false);
    },
    doSearch() {
      this.isSearching = true;
      const options = { params: { search: this.search.keyword } };
      this.$axios
        .$get("/api/v1/search/", options)
        .then((res) => {
          this.results = Object.assign({}, this.$common.cloneJSON(res));
          this.isSearching = false;
        })
        .catch((e) => {
          this.isSearching = false;
        });
    },
    getURL(type, id) {
      const _prefix = PREFIX_ROUTES[type];
      if (!_prefix || !id) return undefined;
      return _prefix + id;
    },
  },
};
