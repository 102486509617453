export const initialState = {
  isLoading: false,
  isFetching: false,
  isFetchingItem: false,
  isCreating: false,
  isDeleting: false,
  isUpdating: false,
  currentPage: 1,
  itemCount: 0,
  totalPage: 0,
  perPage: 20,
  dataList: []
};
