
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

export default {
  name: "AuthenticationForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loadingURL: false,
    };
  },
  computed: {
    ...mapState("app", ["visibleAuthDialog"]),
  },
  methods: {
    ...mapActions("app", ["setAuthDialogVisibility"]),
    ...mapActions("auth", ["getCamDigiKeyAuthURL"]),
    closeDialog(v) {
      this.setAuthDialogVisibility(false);
    },
    getAuthURL() {
      this.loadingURL = true;
      this.getCamDigiKeyAuthURL()
        .then((res) => {
          localStorage.redirect = this.$route.fullPath;
          window.location.href = res.login_url;
        })
        .catch((e) => {
          this.loadingURL = false;
        });
    },
  },
};
